import React, { Component } from "react";
import Navbar from "../components/Layout/Navbar";
import Footer from "../components/Layout/Footer";
import Facility from "../components/Common/Facility";
import Breadcrumb from "../components/Common/Breadcrumb";

class Index extends Component {
	render() {
		return (
			<>
				<Navbar />

				<Breadcrumb title="About Us" />

				<section className="about-area ptb-60">
					<div className="container">
						<div className="row align-items-center">
							<div className="col-lg-6 col-md-12">
								<div className="about-content">
									<h2>About Our Store</h2>
									<p>
										At Poly-Lite, we are committed to your success. Of course, that's what everyone says. But our actions speak
										louder than words. Not only do we have the best resins in the business, but our tool prices are lower and our
										service is better. So save your money, build your business, and depend on us. We're here for you.
									</p>
									{/* <div className="signature mb-0">
                                        <img src="/images/signature.png" alt="image" />
                                    </div> */}
								</div>
								<div className="about-content pt-4">
									<h2>Distributors</h2>
									<h6>Poly-Lite Overseas Authorized Distributor - Russia/Ukraine</h6>
									<a>phone: +7 495-664-20-96</a>
									<a target="_blank" href="http://www.insafe.ru/" rel="noopener noreferrer">
										www.insafe.ru
									</a>
									<a href="mailto:sales@insafe.ru">sales@insafe.ru</a>
								</div>
								<div className="about-content pt-4">
									<h6>Poly-Lite West - Bruce Quande</h6>
									<a>phone: 1-800-262-1716 / 406-721-1344</a>
									<a> Mon-Fri 9am to 5pm MST </a>
								</div>
								<div className="about-content pt-4">
									<h6>REVEX Export Department</h6>
									<a>phone: +1 248-669-8100</a>
									<a target="_blank" href="http://www.revex.us/" rel="noopener noreferrer">
										www.revex.us
									</a>
									<a href="mailto:info@revex.us">info@revex.us</a>
								</div>
							</div>

							<div className="col-lg-6 col-md-12">
								<div className="about-image">
									<img src="/assets/distributors.jpg" className="about-img1" alt="image" />
								</div>
							</div>
						</div>
					</div>
				</section>

				{/* <Testimonials /> */}

				<Facility />

				<Footer />
			</>
		);
	}
}

export default Index;
