import React from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link
} from "react-router-dom";

import Home from './pages/index';
import Login from './pages/login';
import Account from './pages/account';
import Contact from './pages/contact-us';
import About from './pages/about';
import Cart from './pages/cart';
import Checkout from './pages/checkout';
import Signup from './pages/signup';
import Thankyou from './pages/thankyou';


function App(props) {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/account" element={<Account />} />
            <Route path="/contact-us" element={<Contact />} />
            <Route path="/about" element={<About />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/thankyou" element={<Thankyou />} />
        </Routes>
    );
}
export default App;