import { useMemo } from "react";
import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunkMiddleware from "redux-thunk";

import {
	ADD_TO_CART,
	REMOVE_ITEM,
	SUB_QUANTITY,
	ADD_QUANTITY,
	ADD_SHIPPING,
	ADD_QUANTITY_WITH_NUMBER,
	RESET_CART,
	ADD_TO_COMPARE,
	REMOVE_ITEM_FROM_COMPARE,
	GET_PRODUCTS,
} from "../actions/action-types/action-names";

let store;

const initialState = {
	products: [],
	addedItems: [],
	addedItemsToCompare: [],
	total: 0,
	shipping: 0,
	userData: "",
};

const reducers = (state = initialState, action) => {
	if (action.type === ADD_TO_CART) {
		var addedItem = state.products.find((item) => item.id === action.id);
		var addedItems = localStorage.getItem("addedItems");
		addedItems = JSON.parse(addedItems);
		var totalWeight = localStorage.getItem("totalWeight");
		if (totalWeight === null) {
			totalWeight = 0;
		}
		var total = localStorage.getItem("total");
		total = JSON.parse(total);
		//============================================//IF NOTHING ON CART
		if (addedItems === null) {
			var addedItems = [];
			addedItem.quantity = 1;
			let newTotal = total + addedItem.price;
			var test = [...addedItems, addedItem];
			localStorage.setItem("addedItems", JSON.stringify(test));
			localStorage.setItem("total", JSON.stringify(newTotal));

			localStorage.setItem("totalWeight", JSON.stringify(Number(totalWeight) + Number(addedItem.weight)));

			return {
				...state,
				addedItems: [...state.addedItems, addedItem],
				total: newTotal,
			};
		} else {
			var newArray = [];
			var existed_item = addedItems.find((item) => action.id === item.id);
			if (existed_item) {
				var otherItems = addedItems.filter((item) => action.id !== item.id);

				existed_item.quantity += 1;

				otherItems.push(existed_item);

				localStorage.setItem("addedItems", JSON.stringify(otherItems));
				localStorage.setItem("total", JSON.stringify(total + addedItem.price));

				localStorage.setItem("totalWeight", JSON.stringify(Number(totalWeight) + Number(addedItem.weight)));

				return {
					...state,
					total: total + addedItem.price,
				};
			} else {
				addedItem.quantity = 1;
				let newTotal = total + addedItem.price;
				var test = [...addedItems, addedItem];
				localStorage.setItem("addedItems", JSON.stringify(test));
				localStorage.setItem("total", JSON.stringify(newTotal));

				localStorage.setItem("totalWeight", JSON.stringify(Number(totalWeight) + Number(addedItem.weight)));

				return {
					...state,
					addedItems: [...state.addedItems, addedItem],
					total: newTotal,
				};
			}
		}
	}

	if (action.type === ADD_TO_COMPARE) {
		let addedItemToCompare = state.products.find((item) => item.id === action.id);
		addedItemToCompare.quantity = 1;
		return {
			...state,
			addedItemsToCompare: [...state.addedItemsToCompare, addedItemToCompare],
		};
	}

	if (action.type === ADD_QUANTITY_WITH_NUMBER) {
		var addedItem = state.products.find((item) => item.id === action.id);
		var addedItems = localStorage.getItem("addedItems");
		addedItems = JSON.parse(addedItems);
		var totalWeight = localStorage.getItem("totalWeight");
		if (totalWeight === null) {
			totalWeight = 0;
		}

		//============================================//IF NOTHING ON CART
		if (addedItems === null) {
			addedItems = [];
		}

		let existed_item = addedItems.find((item) => action.id === item.id);

		if (existed_item) {
			addedItem.quantity += action.qty;
			var total = localStorage.getItem("total");
			total = Number(total);

			var otherItems = addedItems.filter((item) => action.id !== item.id);
			existed_item.quantity += action.qty;
			otherItems.push(existed_item);
			localStorage.setItem("addedItems", JSON.stringify(otherItems));

			var qtyWeight = Number(addedItem.weight) * action.qty;
			localStorage.setItem("totalWeight", JSON.stringify(Number(totalWeight) + qtyWeight));

			localStorage.setItem("total", JSON.stringify(total + addedItem.price * action.qty));

			return {
				...state,
				total: state.total + addedItem.price * action.qty,
			};
		} else {
			addedItem.quantity = action.qty;
			let newTotal = state.total + addedItem.price * action.qty;

			var test = [...addedItems, addedItem];

			// localStorage.setItem('addedItems', JSON.stringify(addedItems + addedItem));
			localStorage.setItem("addedItems", JSON.stringify(test));
			localStorage.setItem("total", JSON.stringify(newTotal));
			localStorage.setItem("totalWeight", JSON.stringify(Number(totalWeight) + Number(addedItem.weight)));

			return {
				...state,
				addedItems: [...state.addedItems, addedItem],
				total: newTotal,
			};
		}
	}

	if (action.type === REMOVE_ITEM) {
		var addedItems = localStorage.getItem("addedItems");
		addedItems = JSON.parse(addedItems);
		let itemToRemove = addedItems.find((item) => action.id === item.id);
		let new_items = addedItems.filter((item) => action.id !== item.id);
		var totalWeight = localStorage.getItem("totalWeight");
		var total = localStorage.getItem("total");
		total = JSON.parse(total);
		var weightToRemove = Number(itemToRemove.weight) * itemToRemove.quantity;

		let newTotal = total - itemToRemove.price * itemToRemove.quantity;
		let newWeight = Number(totalWeight) - weightToRemove;
		localStorage.setItem("addedItems", JSON.stringify(new_items));
		localStorage.setItem("total", JSON.stringify(newTotal));

		localStorage.setItem("totalWeight", JSON.stringify(newWeight));

		return {
			...state,
			addedItems: new_items,
			total: newTotal,
		};
	}

	if (action.type === REMOVE_ITEM_FROM_COMPARE) {
		let new_items = state.addedItemsToCompare.filter((item) => action.id !== item.id);

		return {
			...state,
			addedItemsToCompare: new_items,
		};
	}

	if (action.type === ADD_QUANTITY) {
		let total = JSON.parse(localStorage.getItem("total"));
		let addedItems = JSON.parse(localStorage.getItem("addedItems"));
		let totalWeight = localStorage.getItem("totalWeight");

		let newItems = addedItems.map((item) => {
			if (item.id === action.id) {
				item.quantity += 1;
				total += item.price;
				totalWeight = Number(totalWeight) + Number(item.weight);
			}
			return item;
		});

		localStorage.setItem("addedItems", JSON.stringify(newItems));
		localStorage.setItem("total", JSON.stringify(total));
		localStorage.setItem("totalWeight", JSON.stringify(totalWeight));

		return {
			...state,
			addedItems: newItems,
			total: total,
		};
	}

	if (action.type === SUB_QUANTITY) {
		let total = JSON.parse(localStorage.getItem("total"));
		let addedItems = JSON.parse(localStorage.getItem("addedItems"));
		let totalWeight = localStorage.getItem("totalWeight");

		let newItems = addedItems
			.map((item) => {
				if (item.id === action.id) {
					if (item.quantity === 1) {
						total -= item.price;
						totalWeight = Number(totalWeight) - Number(item.weight);
						return null; // Mark item for removal
					} else {
						item.quantity -= 1;
						total -= item.price;
						totalWeight = Number(totalWeight) - Number(item.weight);
					}
				}
				return item;
			})
			.filter((item) => item !== null); // Remove items marked for removal

		localStorage.setItem("addedItems", JSON.stringify(newItems));
		localStorage.setItem("total", JSON.stringify(total));
		localStorage.setItem("totalWeight", JSON.stringify(totalWeight));

		return {
			...state,
			addedItems: newItems,
			total: total,
		};
	}

	if (action.type === ADD_SHIPPING) {
		return {
			...state,
			shipping: action.payload,
		};
	}

	if (action.type === "SUB_SHIPPING") {
		return {
			...state,
			shipping: 0,
		};
	}

	if (action.type === RESET_CART) {
		var addedItems = localStorage.getItem("addedItems");
		localStorage.setItem("addedItems", JSON.stringify([]));
		localStorage.setItem("total", JSON.stringify(0));
		return {
			...state,
			addedItems: [],
			total: 0,
			shipping: 0,
		};
	}

	if (action.type === GET_PRODUCTS) {
		localStorage.setItem("products", JSON.stringify(action.payload));

		return {
			...state,
			products: action.payload,
		};
	} else {
		return state;
	}
};

const initStore = (preloadedState = initialState) => {
	return createStore(reducers, preloadedState, composeWithDevTools(applyMiddleware(thunkMiddleware)));
};

export const initializeStore = (preloadedState) => {
	let _store = store ?? initStore(preloadedState);

	// After navigating to a page with an initial Redux state, merge that state
	// with the current state in the store, and create a new store
	if (preloadedState && store) {
		_store = initStore({
			...store.getState(),
			...preloadedState,
		});
		// Reset the current store
		store = undefined;
	}

	// For SSG and SSR always create a new store
	if (typeof window === "undefined") return _store;
	// Create the store once in the client
	if (!store) store = _store;

	return _store;
};

export const useStore = (initialState) => {
	const store = useMemo(() => initializeStore(initialState), [initialState]);
	return store;
};

export default reducers;
