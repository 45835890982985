import React from 'react';
import { Link } from 'react-router-dom';

import Navbar from '../components/Layout/Navbar';
import Facility from '../components/shop-style-one/Facility';
import Footer from '../components/Layout/Footer';

const ThankYou = () => {
    return (
        <>
            <Navbar />

            <div className="thank-you-area">
                <div className="container">
                    <h1>Thank You</h1>
                    <Link to="/">
                        <a className="btn btn-primary">Go Home</a>
                    </Link>
                </div>
            </div>

            <Facility />

            <Footer />
        </>
    );
}

export default ThankYou;
