import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Cart from "../Modal/Cart";

class MegaMenu extends Component {
	state = {
		display: false,
		searchForm: false,
		collapsed: true,
		loggedInUser: null,
		userData: localStorage.getItem("userData"),
	};
	handleCart = () => {
		this.setState((prevState) => {
			return {
				display: !prevState.display,
			};
		});
	};

	handleSearchForm = () => {
		this.setState((prevState) => {
			return {
				searchForm: !prevState.searchForm,
			};
		});
	};

	toggleNavbar = () => {
		this.setState({
			collapsed: !this.state.collapsed,
		});
	};

	componentDidMount() {
		let elementId = document.getElementById("navbar");
		document.addEventListener("scroll", () => {
			if (window.scrollY > 170) {
				elementId.classList.add("is-sticky");
			} else {
				elementId.classList.remove("is-sticky");
			}
		});
		window.scrollTo(0, 0);

		try {
			var loggedInUser = localStorage.getItem("userID");
			this.setState({ loggedInUser });
			if (this.state.loggedInUser !== null) {
				console.log("LOGGED", this.state.loggedInUser);
			}
		} catch (err) {
			console.log("ERROR", err);
		}
	}

	render() {
		const { collapsed } = this.state;
		const classOne = collapsed ? "collapse navbar-collapse" : "collapse navbar-collapse show";
		const classTwo = collapsed ? "navbar-toggler navbar-toggler-right collapsed" : "navbar-toggler navbar-toggler-right";

		let { products } = this.props;
		let { loggedInUser } = this.state;
		let { userData } = this.state;
		userData = JSON.parse(userData);

		return (
			<>
				<div className="navbar-area">
					<div id="navbar" className="comero-nav">
						<div className="container">
							<div className="phone-number">
								<i className="fas fa-phone"></i> 1-800-245-1021
							</div>
							<nav className="navbar navbar-expand-md navbar-light">
								<Link to="/">
									<a className="navbar-brand">
										<img src="/assets/logo.png" alt="logo" />
									</a>
								</Link>

								<button
									onClick={this.toggleNavbar}
									className={classTwo}
									type="button"
									data-toggle="collapse"
									data-target="#navbarSupportedContent"
									aria-controls="navbarSupportedContent"
									aria-expanded="false"
									aria-label="Toggle navigation">
									<span className="icon-bar top-bar"></span>
									<span className="icon-bar middle-bar"></span>
									<span className="icon-bar bottom-bar"></span>
								</button>

								<div className={classOne} id="navbarSupportedContent">
									<ul className="navbar-nav">
										<li className="nav-item">
											<Link to="/">
												<a style={{ fontSize: 18 }} className="active">
													Home
												</a>
											</Link>
										</li>

										<li className="nav-item">
											<Link to="/about">
												<a style={{ fontSize: 18 }}>About Us</a>
											</Link>
										</li>
										<li className="nav-item">
											<Link to="/contact-us">
												<a style={{ fontSize: 18 }}>Contact Us</a>
											</Link>
										</li>
									</ul>

									<div className="others-option">
										<div className="option-item">
											<Link to={loggedInUser == null ? "/Login" : "/Account"}>
												<a style={{ fontSize: 18 }}>{loggedInUser == null ? "Login" : "Hi " + userData.name}</a>
											</Link>
										</div>

										<div className="option-item">
											<Link to="#">
												<a
													style={{ fontSize: 18 }}
													onClick={(e) => {
														e.preventDefault();
														this.handleCart();
													}}>
													Cart({products.length}) <i className="fas fa-shopping-bag"></i>
												</a>
											</Link>
										</div>
									</div>
								</div>
							</nav>
						</div>
					</div>
				</div>

				{this.state.display ? <Cart onClick={this.handleCart} /> : ""}
			</>
		);
	}
}

const mapStateToProps = (state) => {
	try {
		var products = localStorage.getItem("addedItems");
		products = JSON.parse(products);
		if (products === null) {
			products = [];
		}
		return {
			products,
		};
	} catch (err) {
		console.log("ERR MEGAMENU CART", err);
	}
};

export default connect(mapStateToProps)(MegaMenu);
