import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../components/Layout/Footer";
import Facility from "../components/Common/Facility";
// import Breadcrumb from '../components/Common/Breadcrumb';
import { toast } from "react-toastify";
import axios from "axios";
const baseURL = "https://mitserver.app:1515";

const Login = () => {
	const navigate = useNavigate();
	const loggedInUser = localStorage.getItem("userID");

	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [showResetModal, setShowResetModal] = useState(false);
	const [resetEmail, setResetEmail] = useState("");
	const [showPassword, setShowPassword] = useState(false);

	useEffect(() => {
		try {
			console.log("LOGGED USER", loggedInUser);

			if (loggedInUser !== null) {
				console.log("LOGGED", loggedInUser);
				navigate("/");
			}
		} catch (err) {
			console.log("ERROR", err);
		}
	}, []);

	async function login(e) {
		e.preventDefault();
		const response = await axios.post(baseURL + "/login", {
			email,
			password,
		});
		var data = response.data;
		var result = data.result;
		console.log("RESP LOGIN", result);

		if (result.ok === 1) {
			toast.success(result.text, {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
			});
			var data1 = result.data;
			localStorage.setItem("userID", data1.id);
			localStorage.setItem("userData", JSON.stringify(data1));
			navigate(-1);
		} else {
			toast.error(result.text, {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
			});
		}
	}

	const handleResetPassword = async (e) => {
		e.preventDefault();
		try {
			const response = await axios.post(`${baseURL}/forgot`, { email: resetEmail });
			const { result } = response.data;
			if (result.ok === 1) {
				toast.success(result.text, {
					position: "top-right",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
				});
				setShowResetModal(false);
				setResetEmail("");
			} else {
				toast.error(result.text, {
					position: "top-right",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
				});
			}
		} catch (error) {
			toast.error("There is an error", {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
			});
		}
	};

	return (
		<>
			{/* <Navbar />

            <Breadcrumb title="Login" /> */}

			{/* Modal para restablecer la contraseña */}
			{showResetModal && (
				<div className="modal show" style={{ display: "block" }}>
					<div className="modal-dialog">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Forgot Password</h5>
								<button type="button" className="close" onClick={() => setShowResetModal(false)}>
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<form onSubmit={handleResetPassword}>
									<div className="form-group">
										<label htmlFor="resetEmail">Email</label>
										<input
											type="email"
											className="form-control"
											id="resetEmail"
											value={resetEmail}
											onChange={(e) => setResetEmail(e.target.value)}
											required
										/>
									</div>
									<button type="submit" className="btn btn-primary">
										Reset
									</button>
								</form>
							</div>
						</div>
					</div>
				</div>
			)}

			<section className="login-area ptb-60">
				<div className="container">
					<div className="row">
						<div className="col-lg-6 col-md-12">
							<div className="login-content">
								<div className="section-title">
									<h2>
										<span className="dot"></span> Login
									</h2>
								</div>

								<form className="login-form">
									<div className="form-group">
										<label>Email</label>
										<input
											type="email"
											className="form-control"
											placeholder="Enter your e-mail"
											id="email"
											name="email"
											onChange={(e) => setEmail(e.target.value)}
										/>
									</div>

									<div className="form-group position-relative">
										<label>Password</label>
										<input
											type={showPassword ? "text" : "password"}
											className="form-control"
											placeholder="Enter your password"
											id="password"
											name="password"
											onChange={(e) => setPassword(e.target.value)}
										/>
										<span className="password-toggle position-absolute" onClick={() => setShowPassword(!showPassword)}>
											{!showPassword ? <i className="fas fa-eye-slash"></i> : <i className="fas fa-eye"></i>}
										</span>
									</div>

									<button type="submit" className="btn btn-primary" onClick={login}>
										{loggedInUser === "" ? "Login" : "Account"}
									</button>

									<Link to="#">
										<a className="forgot-password" onClick={() => setShowResetModal(true)}>
											Forgot your password?
										</a>
									</Link>
								</form>
							</div>
						</div>

						<div className="col-lg-6 col-md-12">
							<div className="new-customer-content">
								<div className="section-title">
									<h2>
										<span className="dot"></span> New Customer
									</h2>
								</div>

								<span>Create a Account</span>
								<p>
									Sign up for a free account at our store. Registration is quick and easy. It allows you to be able to order from
									our shop. To start shopping click register.
								</p>
								<Link to="/signup">
									<a className="btn btn-light">Create A Account</a>
								</Link>
							</div>
						</div>
					</div>
				</div>
			</section>

			<Facility />

			<Footer />
		</>
	);
};

export default Login;
