import React, { Component } from "react";
import { Link } from "react-router-dom";

class Footer extends Component {
	render() {
		return (
			<footer className="footer-area">
				<div className="container">
					<div className="row">
						<div className="col-lg-3 col-md-6">
							<div className="single-footer-widget">
								<h3></h3>
								<div className="logo">
									<Link to="/">
										<a>
											<img src="/assets/kemxert.jpg " alt="logo" />
										</a>
									</Link>
								</div>

								<p>
									We are proud to introduce that Kemxert Corporation is now part of the Poly-Lite family of products. We've been
									able to expand our product line and offer more great resins and windshield replacement products. Those of you who
									already know the Kemxert brand can expect the same quality product you've come to know with the Kemxert product
									line. You can now find Kemxert products in the Poly-Lite online catalog.
								</p>
							</div>
						</div>

						<div className="col-lg-3 col-md-6">
							<div className="single-footer-widget">
								<h3></h3>

								<ul className="quick-links"></ul>
							</div>
						</div>

						<div className="col-lg-3 col-md-6">
							<div className="single-footer-widget">
								<ul className="information-links"></ul>
							</div>
						</div>

						<div className="col-lg-3 col-md-6">
							<div className="single-footer-widget">
								<h3>Contact Us</h3>

								<ul className="footer-contact-info">
									<li>
										<i className="fas fa-map-marker-alt"></i>
										1952 Stanton Street, York <br /> PA. 17404, USA.
									</li>
									<li>
										<i className="fas fa-phone"></i>
										Call Us: <a href="tel:1-800-245-1021">1-800-245-1021 / 717-845-1596</a>
									</li>
									<li>
										<i className="far fa-envelope"></i>
										Email Us: <a href="mailto:ksurdich@poly-lite.com">ksurdich@poly-lite.com</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>

				<div className="copyright-area">
					<div className="container">
						<div className="row align-items-center">
							<div className="col-lg-6 col-md-6">
								<p>
									Copyright &copy; 2022 Poly-Lite. Designed By{" "}
									<a href="https://mitfamily.com/" target="_blank" rel="noreferrer">
										MIT Family
									</a>
								</p>
							</div>
						</div>
					</div>
				</div>
			</footer>
		);
	}
}

export default Footer;
