import React, { Component } from "react";
import headerImage from "../../assets/header3.jpg"; // Importa la imagen

class Banner extends Component {
	render() {
		return (
			<div className="main-banner">
				<div className="d-table">
					<div className="d-table-cell">
						<div className="container">
							<div className="main-banner-content">
								<img src={headerImage} alt="Banner" className="banner-image" />
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Banner;
