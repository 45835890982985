import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { removeItem, addQuantity, subtractQuantity } from "../../store/actions/actions";
import { toast } from "react-toastify";

class CartProduct extends Component {
	handleRemove = (id) => {
		this.props.removeItem(id);

		toast.error("Removed from cart", {
			position: "bottom-left",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
		});
	};

	//to add the quantity
	handleAddQuantity = (id) => {
		this.props.addQuantity(id);
	};

	//to substruct from the quantity
	handleSubtractQuantity = (id) => {
		this.props.subtractQuantity(id);
	};

	render() {
		let cartItems = this.props.products.length ? (
			this.props.products.map((data, idx) => {
				return (
					<tr key={idx}>
						<td className="product-thumbnail">
							<Link href="#">
								<a>
									<img src={data.image} alt="item" />
								</a>
							</Link>
						</td>

						<td className="product-name">
							<a>{data.name}</a>
							<ul>
								<li>
									Code: <strong>{data.code}</strong>
								</li>
							</ul>
						</td>

						<td className="product-price">
							<span className="unit-amount">${data.price}</span>
						</td>

						<td className="product-quantity">
							<div className="input-counter">
								<span
									className="minus-btn"
									onClick={() => {
										this.handleSubtractQuantity(data.id);
									}}>
									<i className="fas fa-minus"></i>
								</span>
								<input type="text" value={data.quantity} min="1" max={10} readOnly={true} />
								<span
									className="plus-btn"
									onClick={() => {
										this.handleAddQuantity(data.id);
									}}>
									<i className="fas fa-plus"></i>
								</span>
							</div>
						</td>

						<td className="product-subtotal">
							<span className="subtotal-amount">${data.price * data.quantity}</span>

							<Link href="#">
								<a
									className="remove"
									onClick={(e) => {
										e.preventDefault();
										this.handleRemove(data.id);
									}}>
									<i className="far fa-trash-alt"></i>
								</a>
							</Link>
						</td>
					</tr>
				);
			})
		) : (
			<tr>
				<td className="product-thumbnail" colSpan="5">
					<p>Empty.</p>
				</td>
			</tr>
		);

		return (
			<>
				<table className="table table-bordered">
					<thead>
						<tr>
							<th scope="col">Product</th>
							<th scope="col">Name</th>
							<th scope="col">Unit Price</th>
							<th scope="col">Quantity</th>
							<th scope="col">Total</th>
						</tr>
					</thead>
					<tbody>{cartItems}</tbody>
				</table>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	var products = localStorage.getItem("addedItems");
	products = JSON.parse(products);
	console.log("PRODUCTS", products);
	if (products === null) {
		products = [];
	}
	var totalWeight = localStorage.getItem("totalWeight");

	// console.log("TOTSL WEIGHT", totalWeight);
	return {
		products,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		removeItem: (id) => {
			dispatch(removeItem(id));
		},
		addQuantity: (id) => {
			dispatch(addQuantity(id));
		},
		subtractQuantity: (id) => {
			dispatch(subtractQuantity(id));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(CartProduct);
