import React from "react";
import { Link } from "react-router-dom";
import QuickView from "../Modal/QuickView";
import AddToCart from "../Shared/AddToCart";

class Products extends React.Component {
	state = {
		modalOpen: false,
		modalData: null,
	};

	openTabSection = (evt, tabNmae) => {
		let i, tabcontent, tablinks;
		tabcontent = document.getElementsByClassName("tabs_item");

		for (i = 0; i < tabcontent.length; i++) {
			tabcontent[i].classList.remove("fadeInUp");
			tabcontent[i].style.display = "none";
		}

		tablinks = document.getElementsByTagName("li");
		for (i = 0; i < tablinks.length; i++) {
			tablinks[i].className = tablinks[i].className.replace("current", "");
		}

		document.getElementById(tabNmae).style.display = "block";
		document.getElementById(tabNmae).className += " fadeInUp animated";
		evt.currentTarget.className += "current";
	};

	openModal = () => {
		this.setState({ modalOpen: true });
	};

	closeModal = () => {
		this.setState({ modalOpen: false });
	};

	handleModalData = (data) => {
		this.setState({
			modalData: data,
		});
	};

	render() {
		let { products } = this.props;
		const { modalOpen } = this.state;
		return (
			<section className="all-products-area pb-60">
				<div className="container">
					<div className="tab products-category-tab">
						<div className="row">
							<div className="col-lg-12 col-md-12">
								<ul className="tabs"></ul>
							</div>

							<div
								style={{
									display: "flex",
									flexWrap: "nowrap",
								}}>
								<div
									className="col-lg-4 col-md-4 col-sm-4 col-4"
									onClick={(e) => {
										e.preventDefault();
										this.openTabSection(e, "tab1");
									}}
									onTouchEnd={(e) => {
										e.preventDefault();
										this.openTabSection(e, "tab1");
									}}>
									<div className="offer-box">
										<img src="/assets/equipment.jpg" alt="image" />

										<div className="category">
											<h4>Equipment</h4>
										</div>

										<div className="box-inner">
											<h3>Equipment</h3>
											<a>The best equipment tools to work</a>
										</div>
									</div>
								</div>

								<div
									className="col-lg-4 col-md-4 col-sm-4 col-4"
									onClick={(e) => {
										e.preventDefault();
										this.openTabSection(e, "tab2");
									}}
									onTouchEnd={(e) => {
										e.preventDefault();
										this.openTabSection(e, "tab2");
									}}>
									<div className="offer-box">
										<img src="/assets/resins.jpg" alt="image" />

										<div className="category">
											<h4>Resins</h4>
										</div>

										<div className="box-inner">
											<h3>Resins</h3>
											<a>Best Resins and excellent quality</a>
										</div>
									</div>
								</div>

								<div
									className="col-lg-4 col-md-4 col-sm-4 col-4"
									onClick={(e) => {
										e.preventDefault();
										this.openTabSection(e, "tab3");
									}}
									onTouchEnd={(e) => {
										e.preventDefault();
										this.openTabSection(e, "tab3");
									}}>
									<div className="offer-box">
										<img src="/assets/kits.jpg" alt="image" />

										<div className="category">
											<h4>Kits</h4>
										</div>

										<div className="box-inner">
											<h3>Kits</h3>
											<a>Complete Kits to start working</a>
										</div>
									</div>
								</div>
							</div>

							<div className="col-lg-12 col-md-12 pt-60">
								<div className="tab_content">
									<div id="tab1" className="tabs_item">
										<div className="row">
											{products
												.filter((f) => f.category === 1)
												.map((data, idx) => (
													<div className="col-lg-3 col-sm-6 col-6" key={idx}>
														<div className="single-product-box">
															<div className="product-image">
																{/* <Link to="/product/[id]" as={`/product/${data.id}`}> */}
																<Link href="#">
																	<a
																		data-tip="View"
																		data-place="left"
																		onClick={(e) => {
																			e.preventDefault();
																			this.openModal();
																			this.handleModalData(data);
																		}}>
																		<img src={data.image} alt="image" />
																	</a>
																</Link>

																<ul>
																	<li>
																		<Link href="#">
																			<a
																				data-tip="View"
																				data-place="left"
																				onClick={(e) => {
																					e.preventDefault();
																					this.openModal();
																					this.handleModalData(data);
																				}}>
																				<i className="far fa-eye"></i>
																			</a>
																		</Link>
																	</li>
																	{/* <li>
                                                                    <Link href="#">
                                                                        <a data-tip="Add to Wishlist" data-place="left">
                                                                            <i className="far fa-heart"></i>
                                                                        </a>
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    {
                                                                        this.compareButton(data.id)
                                                                    }
                                                                </li> */}
																</ul>
															</div>

															<div className="product-content">
																<h3>
																	<Link to="/product/[id]" as={`/product/${data.id}`}>
																		<a>{data.name}</a>
																	</Link>
																</h3>

																<div className="product-price">
																	<span className="new-price">${data.price}</span>
																</div>

																<div className="rating">
																	<i className="fas fa-star"></i>
																	<i className="fas fa-star"></i>
																	<i className="fas fa-star"></i>
																	<i className="fas fa-star"></i>
																	<i className="fas fa-star"></i>
																</div>

																<AddToCart {...data} />
															</div>
														</div>
													</div>
												))}
										</div>
									</div>

									<div id="tab2" className="tabs_item">
										<div className="row">
											{products
												.filter((f) => f.category === 2)
												.map((data, idx) => (
													<div className="col-lg-3 col-sm-6 col-6" key={idx}>
														<div className="single-product-box">
															<div className="product-image">
																{/* <Link to="/product/[id]" as={`/product/${data.id}`}> */}
																<Link href="#">
																	<a
																		data-tip="View"
																		data-place="left"
																		onClick={(e) => {
																			e.preventDefault();
																			this.openModal();
																			this.handleModalData(data);
																		}}>
																		<img src={data.image} alt="image" />
																	</a>
																</Link>

																<ul>
																	<li>
																		<Link href="#">
																			<a
																				data-tip="View"
																				data-place="left"
																				onClick={(e) => {
																					e.preventDefault();
																					this.openModal();
																					this.handleModalData(data);
																				}}>
																				<i className="far fa-eye"></i>
																			</a>
																		</Link>
																	</li>
																	{/* <li>
                                                                    <Link href="#">
                                                                        <a data-tip="Add to Wishlist" data-place="left">
                                                                            <i className="far fa-heart"></i>
                                                                        </a>
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    {
                                                                        this.compareButton(data.id)
                                                                    }
                                                                </li> */}
																</ul>
															</div>

															<div className="product-content">
																<h3>
																	<Link to="/product/[id]" as={`/product/${data.id}`}>
																		<a>{data.name}</a>
																	</Link>
																</h3>

																<div className="product-price">
																	<span className="new-price">${data.price}</span>
																</div>

																<div className="rating">
																	<i className="fas fa-star"></i>
																	<i className="fas fa-star"></i>
																	<i className="fas fa-star"></i>
																	<i className="fas fa-star"></i>
																	<i className="fas fa-star"></i>
																</div>

																<AddToCart {...data} />
															</div>
														</div>
													</div>
												))}
										</div>
									</div>

									<div id="tab3" className="tabs_item">
										<div className="row">
											{products
												.filter((f) => f.category === 3)
												.map((data, idx) => (
													<div className="col-lg-3 col-sm-6 col-6" key={idx}>
														<div className="single-product-box">
															<div className="product-image">
																{/* <Link to="/product/[id]" as={`/product/${data.id}`}> */}
																<Link href="#">
																	<a
																		data-tip="View"
																		data-place="left"
																		onClick={(e) => {
																			e.preventDefault();
																			this.openModal();
																			this.handleModalData(data);
																		}}>
																		<img src={data.image} alt="image" />
																	</a>
																</Link>

																<ul>
																	<li>
																		<Link href="#">
																			<a
																				data-tip="View"
																				data-place="left"
																				onClick={(e) => {
																					e.preventDefault();
																					this.openModal();
																					this.handleModalData(data);
																				}}>
																				<i className="far fa-eye"></i>
																			</a>
																		</Link>
																	</li>
																	{/* <li>
                                                                    <Link href="#">
                                                                        <a data-tip="Add to Wishlist" data-place="left">
                                                                            <i className="far fa-heart"></i>
                                                                        </a>
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    {
                                                                        this.compareButton(data.id)
                                                                    }
                                                                </li> */}
																</ul>
															</div>

															<div className="product-content">
																<h3>
																	<Link to="/product/[id]" as={`/product/${data.id}`}>
																		<a>{data.name}</a>
																	</Link>
																</h3>

																<div className="product-price">
																	<span className="new-price">${data.price}</span>
																</div>

																<div className="rating">
																	<i className="fas fa-star"></i>
																	<i className="fas fa-star"></i>
																	<i className="fas fa-star"></i>
																	<i className="fas fa-star"></i>
																	<i className="fas fa-star"></i>
																</div>

																<AddToCart {...data} />
															</div>
														</div>
													</div>
												))}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				{modalOpen ? <QuickView closeModal={this.closeModal} modalData={this.state.modalData} /> : ""}
			</section>
		);
	}
}

export default Products;
