import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify'
import Cart from '../Modal/Cart';

const AddToCart = ({ id }) => {
    const dispatch = useDispatch();
    const [display, setDisplay] = useState(false);

    const handleAddToCart = (id) => {
        dispatch({
            type: 'ADD_TO_CART',
            id
        })
        // handleCart();
        toast.success('Added to the cart', {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
        });
    }

    const handleCart = () => {
        setDisplay(!display);
    }

    return (
        <>
            <Link href="#">
                <a
                    className="btn btn-light"
                    onClick={(e) => {
                        e.preventDefault(); handleAddToCart(id)
                    }}
                >
                    Add to Cart
                </a>
            </Link>
            {display ? <Cart onClick={handleCart} /> : ''}
        </>
    )
}

export default AddToCart;