import React, { Component } from 'react';
import Cards from 'react-credit-cards';
import { ToastContainer, toast } from 'react-toastify';

class Card extends Component {

    state = {
        cvc: '',
        expiry: '',
        focus: '',
        name: '',
        number: '',
    };

    handleInputFocus = (e) => {
        this.setState({ focus: e.target.name });
    }

    handleInputChange = (e) => {
        const { name, value } = e.target;

        this.setState({ [name]: value });
    }

    render() {
        return (
            <div className="modal fade  show" style={{ paddingRight: '16px', display: 'block' }}>
                <ToastContainer />
                {/* <div className="modal-dialog modal-dialog-centered" role="document"> */}
                {/* <div className="modal-content"> */}
                {/* <div className="row align-items-center"> */}
                <div id="PaymentForm">
                    <Cards
                        cvc={this.state.cvc}
                        expiry={this.state.expiry}
                        focused={this.state.focus}
                        name={this.state.name}
                        number={this.state.number}
                    />
                    <form>
                        <input
                            type="tel"
                            name="number"
                            placeholder="Card Number"
                            onChange={this.handleInputChange}
                            onFocus={this.handleInputFocus}
                        />
                        ...
                    </form>
                </div>
                {/* </div> */}
                {/* </div> */}
                {/* </div> */}
            </div>

        );
    }
}

export default Card
