import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Navbar from "../components/Layout/Navbar";
import Banner from "../components/shop-style-one/Banner";
import Products from "../components/shop-style-one/Products";
import Facility from "../components/shop-style-one/Facility";
import Footer from "../components/Layout/Footer";
import { getProducts } from "../store/actions/actions";

const Index = () => {
	const products = useSelector((state) => state.products);
	const dispatch = useDispatch();
	const addedItemsToCompare = useSelector((state) => state.addedItemsToCompare);

	useEffect(() => {
		dispatch(getProducts);
		// localStorage.clear();
		// localStorage.removeItem('addedItems');
	}, []);

	return (
		<>
			<Navbar />

			<Banner />

			<div className="text-center mt-3 w-75 mx-auto">
				<h2>Poly-Lite Resins</h2>
				<p>
					Poly-Lite has served the Windshield Repair Industry for years. Our quality products, tools and support have produced thousands of
					satisfied customers. Our resin formulas are the best the industry has to offer and our prices are unbeatable.{" "}
				</p>
				<p>
					We do not stop at quality products. Our business kits and supplies have helped hundreds of businesses expand their current
					services and played a direct role in helping countless people start their own profitable Windshield Repair Business.
				</p>
			</div>

			<Products products={products} CompareProducts={addedItemsToCompare} />

			<Facility />

			<Footer />

			{/* <AddsModal /> */}
		</>
	);
};

export default Index;
