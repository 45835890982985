import {
	ADD_TO_CART,
	REMOVE_ITEM,
	SUB_QUANTITY,
	ADD_QUANTITY,
	ADD_QUANTITY_WITH_NUMBER,
	RESET_CART,
	ADD_TO_COMPARE,
	REMOVE_ITEM_FROM_COMPARE,
	GET_PRODUCTS,
} from "./action-types/action-names";
import axios from "axios";
const baseURL = "https://mitserver.app:1515";

//add cart action
export const addToCart = (id) => {
	return {
		type: ADD_TO_CART,
		id,
	};
};
//remove item action
export const removeItem = (id) => {
	return {
		type: REMOVE_ITEM,
		id,
	};
};
//subtract qt action
export const subtractQuantity = (id) => {
	return {
		type: SUB_QUANTITY,
		id,
	};
};
//add qt action
export const addQuantity = (id) => {
	return {
		type: ADD_QUANTITY,
		id,
	};
};

//add qt action with quantity number
export const addQuantityWithNumber = (id, qty) => {
	return {
		type: ADD_QUANTITY_WITH_NUMBER,
		id,
		qty,
	};
};

// Reset cart after form submit
export const resetCart = () => {
	return {
		type: RESET_CART,
	};
};

//add compare action
export const addToCompare = (id) => {
	return {
		type: ADD_TO_COMPARE,
		id,
	};
};
//remove item from compare action
export const removeItemFromCompare = (id) => {
	return {
		type: REMOVE_ITEM_FROM_COMPARE,
		id,
	};
};

export async function getProducts(dispatch) {
	const response = await axios.post(baseURL + "/getLists", {
		list: "products",
	});
	var data = response.data;
	var resp = data.result;
	var result = resp.data;
	// console.log("PRODUCTS RESP", result);
	dispatch({ type: GET_PRODUCTS, payload: result });
}
