import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

class Cart extends Component {
	state = {
		display: false,
	};

	closeCart = () => {
		this.props.onClick(this.state.display);
	};

	render() {
		let { products, total } = this.props;
		return (
			<div
				className="modal right fade show shoppingCartModal"
				style={{
					display: "block",
					paddingRight: "16px",
				}}>
				<div className="modal-dialog" role="document">
					<div className="modal-content">
						<button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeCart}>
							<span aria-hidden="true">&times;</span>
						</button>

						<div className="modal-body">
							<h3>My Cart ({products.length})</h3>
							<div className="product-cart-content">
								{products.length > 0
									? products.map((product, idx) => (
											<div className="product-cart" key={idx}>
												<div className="product-image">
													<img src={product.image} alt="image" />
												</div>

												<div className="product-content">
													<h3>
														<Link href="#">
															<a>{product.name}</a>
														</Link>
													</h3>
													<span>{product.code}</span>
													<div className="product-cart-subtotal">
														<span className="subtotal">${product.price * product.quantity}</span>
														<span>{product.quantity} items</span>
													</div>
												</div>
												<div className="product-cart-subtotal">
													<span>Subtotal</span>
													<span className="subtotal">${total}</span>
												</div>
											</div>
									  ))
									: "Empty"}
							</div>

							<div className="product-cart-btn">
								<Link to="/cart">
									<a className="btn btn-light">View Shopping Cart</a>
								</Link>
								<Link to="/checkout">
									<a className="btn btn-light">View Checkout</a>
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	var products = localStorage.getItem("addedItems");
	products = JSON.parse(products);
	if (products === null) {
		products = [];
	}
	var total = localStorage.getItem("total");
	total = JSON.parse(total);
	return {
		products,
		total,
	};
};

export default connect(mapStateToProps)(Cart);
